window.delayedScriptLoaders = window.delayedScriptLoaders || [];
const monitoredEvents = ["click", "scroll", "press"];
const callMap = {};

document.addEventListener("DOMContentLoaded", () => {
  for (const loadFun of window.delayedScriptLoaders) {
	const key = btoa(loadFun.toString());
	callMap[key] = false;
	setTimeout(() => {
      if (!callMap[key]) {
		loadFun();
		callMap[key] = true;
      }
	}, 5000);
	monitoredEvents.forEach((event) => {
	  document.addEventListener(event, () => {
		if (!callMap[key]) {
		  loadFun();
		  callMap[key] = true;
		}
	  });
	});
  }
}, {once: true});
						 




